import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { IMAGES_PATH, IS_APP } from '@vip/core';
import { HeaderDesktopBaseDirective } from '../header-desktop/header-desktop-base.directive';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DropdownDirective } from '@vip/ui/dropdown';

@UntilDestroy()
@Component({
  selector: 'vip-header-sub-menu-mobile',
  templateUrl: './header-sub-menu-mobile.component.html',
  styleUrls: ['./header-sub-menu-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class HeaderSubMenuMobileComponent
  extends HeaderDesktopBaseDirective
  implements AfterViewInit
{
  dropdownEntregaRetiradaOpen = false;

  @ViewChildren(DropdownDirective)
  dropdowns!: QueryList<DropdownDirective>;

  @Input() fallbackImageUrl?: string;
  constructor(
    @Inject(IMAGES_PATH) private imagesPath: string,
    @Inject(IS_APP) protected isApp: boolean,
    private router: Router,
  ) {
    super();
  }

  get televendasLogo(): string {
    return `${this.imagesPath}logo-televendas.png`;
  }

  get logoSrc(): string {
    return this.isTelevendas ? this.televendasLogo : this.logo;
  }

  get isTelevendasSemCliente(): boolean {
    return this.isTelevendas && !this.cliente;
  }

  get iconEntregaRetirada(): string {
    if (!this.enderecoSelecionado) {
      return 'icon-location_on';
    }
    return this.retiradaLoja ? 'icon-store_mall' : 'icon-location_on';
  }

  get labelEndereco(): string {
    return this.retiradaLoja ? 'Retirar' : 'Entregar';
  }

  ngAfterViewInit(): void {
    this.initListenerCloseDropdownEntregaRetirada();
  }

  goToHome() {
    this.router.navigateByUrl('/');
  }

  initListenerCloseDropdownEntregaRetirada(): void {
    this.closeDropdownEntregaRetirada$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.dropdownEntregaRetiradaOpen) this.dropdowns.first.close();
      });
  }
}
