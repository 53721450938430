<footer
  [ngClass]="isDesktop ? 'classe-para-desktop' : 'classe-para-mobile'"
  class="vip-color-first-default text-sm overflow-y-hidden"
>
  <section
    class="flex flex-col sm:flex-row lg:flex-row justify-between flex-wrap"
  >
    <article
      class="w-full pb-0 sm:mb-0 sm:pb-0 lg:mb-0 lg:pb-0 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-2/12"
      *ngFor="let column of colunasPaginas"
    >
      <div class="w-full">
        <ng-container
          *ngIf="
            shouldShowAccordion(isDesktop);
            then accordionTemplate;
            else normalTemplate
          "
        ></ng-container>
        <ng-template #accordionTemplate>
          <div class="w-full">
            <vip-accordion [collapsing]="collapsing">
              <vip-collapse [expanded]="false">
                <ng-template vipCollapseHeader>
                  <b>{{ column.titulo }}</b>
                </ng-template>
                <ng-template vipCollapseContent>
                  <ul
                    id="{{ column.titulo }}"
                    *ngIf="
                      column.titulo === 'Quem somos' ||
                        column.titulo === 'Forma de Pagamento';
                      else institucionalTemplate
                    "
                  >
                    <ng-container *ngFor="let pagina of column.paginas">
                      <li class="pt-3">{{ pagina?.descricao }}</li>
                    </ng-container>
                    <li>
                      <vip-custom-link
                        [openInNewTab]="false"
                        [url]="
                          '/institucional/pagina/' + column.paginas[0]?.slug
                        "
                        [ariaDescribedby]="'Clique aqui e saiba mais'"
                        [ariaLabel]="'Clique aqui e saiba mais'"
                        [labelledby]="'link-saiba-mais-' + column.titulo"
                        class="underline custom-link whitespace-nowrap"
                        [id]="column?.titulo"
                      >
                        <p>Clique aqui e saiba mais</p>
                      </vip-custom-link>
                    </li>
                  </ul>
                </ng-template>
              </vip-collapse>
            </vip-accordion>
            <ng-template #institucionalTemplate>
              <ul class="pt-3">
                <li class="mb-1" *ngFor="let pagina of column.paginas">
                  <ng-container
                    *ngIf="
                      pagina?.tipo === 'interna' || pagina?.tipo === 'iframe';
                      else externa
                    "
                  >
                    <vip-custom-link
                      [openInNewTab]="false"
                      [url]="redirectToPage(pagina, isDesktop) || ''"
                      [ariaDescribedby]="pagina?.subtitulo"
                      [ariaLabel]="pagina?.subtitulo"
                    >
                      <p>{{ pagina?.subtitulo }}</p>
                    </vip-custom-link>
                  </ng-container>
                  <ng-template #externa>
                    <vip-custom-link
                      [openInNewTab]="true"
                      [url]="pagina?.link || ''"
                      [ariaDescribedby]="pagina?.subtitulo"
                      [ariaLabel]="pagina?.subtitulo"
                    >
                      <p>{{ pagina?.subtitulo }}</p>
                    </vip-custom-link>
                  </ng-template>
                </li>
              </ul>
            </ng-template>
            <hr class="border-t block md:hidden lg:hidden footer-divider" />
          </div>
        </ng-template>

        <ng-template #normalTemplate>
          <div class="w-full">
            <ul
              *ngIf="
                column.titulo === 'Quem somos' ||
                  column.titulo === 'Forma de Pagamento';
                else institucionalTemplate
              "
            >
              <li class="mb-3 lg:mb-2">
                <b>{{ column.titulo }}</b>
              </li>
              <ng-container *ngFor="let pagina of column.paginas">
                <li>
                  {{ pagina?.descricao }}
                </li>

                <li>
                  <vip-custom-link
                    [openInNewTab]="false"
                    [url]="'/institucional/pagina/' + pagina?.slug"
                    [ariaDescribedby]="'Clique aqui e saiba mais'"
                    [ariaLabel]="'Clique aqui e saiba mais'"
                    [labelledby]="'link-saiba-mais-' + pagina?.slug"
                    class="underline custom-link whitespace-nowrap"
                    [useHref]="false"
                  >
                    <p>Clique aqui e saiba mais</p>
                  </vip-custom-link>
                </li>
              </ng-container>
            </ul>
            <ng-template #institucionalTemplate>
              <ul>
                <li class="mb-2">
                  <b>{{ column.titulo }}</b>
                </li>
                <ng-container *ngIf="column?.paginas?.length">
                  <li class="mb-1" *ngFor="let pagina of column.paginas">
                    <vip-custom-link
                      *ngIf="pagina"
                      [openInNewTab]="
                        isExternalLink(redirectToPage(pagina, isDesktop))
                      "
                      [url]="redirectToPage(pagina, isDesktop) || ''"
                      [ariaDescribedby]="pagina?.subtitulo"
                      [ariaLabel]="pagina?.subtitulo"
                      [useHref]="
                        isExternalLink(redirectToPage(pagina, isDesktop))
                      "
                    >
                      <p id="{{ 'institucional' + pagina.id }}">
                        {{ pagina?.subtitulo }}
                      </p>
                    </vip-custom-link>
                  </li>
                </ng-container>
              </ul>
            </ng-template>
          </div>
        </ng-template>

        <hr class="border-t block md:hidden lg:hidden footer-divider" />
      </div>
    </article>
    <article
      *ngIf="!isRelacionamentoEmpty"
      class="vip-relacionamento-cliente-wrapper"
    >
      <ul>
        <li class="mb-2 mt-3 lg:mt-0">
          <b>Relacionamento com o Cliente</b>
        </li>
        <li
          class="mb-1"
          *ngFor="
            let obj of getFilteredContato(relacionamentoClienteViewModel)
              | keyvalue
          "
        >
          <vip-custom-link
            [openInNewTab]="false"
            [url]="handleClickContato(obj.key) || ''"
            [ariaDescribedby]="obj.value"
            [ariaLabel]="obj.value"
            [useHref]="true"
          >
            <div
              (click)="handleClickContato(obj.key)"
              class="flex items-center"
              [ngClass]="{
                'cursor-pointer': obj.key === 'whatsapp',
              }"
              [attr.data-cy]="'vip-contato-link-' + obj.key"
            >
              <i
                class="mr-2 text-xl leading-none secondary {{
                  ICONE_CONTATO[obj.key]
                }}"
              ></i>
              <ng-container *ngIf="obj.key !== 'email'; else emailTemplate">
                <span>{{ obj.value }}</span>
              </ng-container>
              <ng-template #emailTemplate>
                <div class="vip-email-wrapper">
                  <span [title]="obj.value">{{ obj.value }}</span>
                </div>
              </ng-template>
            </div>
          </vip-custom-link>
        </li>
      </ul>
    </article>
    <hr
      [hidden]="isRelacionamentoEmpty"
      class="mb-3 border-t block md:hidden lg:hidden footer-divider"
    />
    <div class="w-full sm:mb-0 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-3/12">
      <div class="w-full items-center">
        <div *ngIf="hasRedesSociais" [class.pt-0]="package || appleId">
          <b class="block">Redes Sociais</b>
          <div class="flex gap-4 pt-2">
            <vip-custom-link
              *ngIf="redesSociais?.url_twitter"
              [openInNewTab]="true"
              [url]="redesSociais?.url_twitter || ''"
              [ariaDescribedby]="'Twitter'"
              [ariaLabel]="'Twitter'"
              class="social-link"
              [useHref]="true"
            >
              <div
                style="mask:url('{{ imagesPath }}twitter-negative.svg');"
                class="w-full h-auto social-svg"
                alt="Twitter"
              ></div>
            </vip-custom-link>

            <vip-custom-link
              *ngIf="redesSociais?.url_instagram"
              [openInNewTab]="true"
              [url]="redesSociais?.url_instagram || ''"
              [ariaDescribedby]="'Instagram'"
              [ariaLabel]="'Instagram'"
              class="social-link"
              [useHref]="true"
            >
              <div
                style="mask:url('{{ imagesPath }}instagram-negative.svg');"
                class="w-full h-auto social-svg"
                alt="Instagram"
              ></div>
            </vip-custom-link>
            <vip-custom-link
              *ngIf="redesSociais?.url_youtube"
              [openInNewTab]="true"
              [url]="redesSociais?.url_youtube || ''"
              [ariaDescribedby]="'Youtube'"
              [ariaLabel]="'Youtube'"
              class="social-link"
              [useHref]="true"
            >
              <div
                style="mask:url('{{ imagesPath }}youtube-negative.svg');"
                class="w-full h-auto social-svg"
                alt="Youtube"
              ></div>
            </vip-custom-link>
          </div>
        </div>
        <hr
          [hidden]="package || appleId"
          class="mt-3 border-t block md:hidden lg:hidden footer-divider"
        />

        <div *ngIf="package || appleId" class="pt-3">
          <b class="block">Baixe o App</b>
          <div class="flex gap-4 pt-2">
            <vip-custom-link
              *ngIf="appleId"
              [openInNewTab]="true"
              [url]="'https://apps.apple.com/br/app/' + appleId"
              [ariaDescribedby]="'Baixe o app no Appstore'"
              [ariaLabel]="'Baixe o app no Appstore'"
              [useHref]="true"
            >
              <vip-image
                imageUrl="{{ imagesPath }}appstore.png"
                classNames="w-full h-auto"
                alt="Baixe o app no Appstore"
              >
              </vip-image>
            </vip-custom-link>

            <vip-custom-link
              *ngIf="package"
              [openInNewTab]="true"
              [url]="
                'https://play.google.com/store/apps/details?id={{
              package
            }}&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
              "
              [ariaDescribedby]="'Baixe o app no Google Play'"
              [ariaLabel]="'Baixe o app no Google Play'"
              [useHref]="true"
            >
              <vip-image
                imageUrl="{{ imagesPath }}googleplay.png"
                classNames="w-full h-auto"
                alt="Baixe o app no Google Play"
              >
              </vip-image>
            </vip-custom-link>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div
    class="mt-6 lg:mt-10"
    data-cy="texto-footer"
    *ngIf="textoFooter"
    [innerHTML]="sanitizer.bypassSecurityTrustHtml(textoFooter)"
  ></div>
  <div
    class="flex flex-col pt-4 lg:mt-7 lg:mb-10 sm:flex-row flex-wrap items-center justify-center gap-y-4"
  >
    <div
      class="mr-0 lg:mr-10"
      [class.mr-10]="hasRedesSociais || package || appleId"
    >
      <vip-custom-link
        [openInNewTab]="true"
        [url]="'https://vipcommerce.com.br'"
        [ariaDescribedby]="'Desenvolvido por: VipCommerce Sistemas'"
        [ariaLabel]="'Desenvolvido por: VipCommerce Sistemas'"
        [useHref]="true"
      >
        <div class="flex gap-4">
          <vip-image
            imageUrl="{{ imagesPath }}logo-vipcommerce.png"
            class="h-auto"
            alt=""
          >
          </vip-image>
          <span class="underline">
            Desenvolvido por<br />
            <b>VipCommerce Sistemas</b>
          </span>
        </div>
      </vip-custom-link>
    </div>
    <div class="mr-0 lg:mr-10" *ngIf="participaSupermercadoOnline === '1'">
      <vip-custom-link
        [openInNewTab]="true"
        [url]="'https://supermercadosonline.com.br'"
        [ariaDescribedby]="
          'Você encontra essa loja em: supermercadosonline.com.br'
        "
        [ariaLabel]="'Você encontra essa loja em: supermercadosonline.com.br'"
        [useHref]="true"
      >
        <div class="flex gap-4">
          <vip-image
            imageUrl="{{ imagesPath }}logo-supermercado-online.png"
            alt=""
            classNames="h-auto"
          >
          </vip-image>
          <span class="underline">
            Você encontra essa loja em<br class="whitespace-nowrap" />
            <b>supermercadosonline.com.br</b>
          </span>
        </div>
      </vip-custom-link>
    </div>
    <div class="flex flex-wrap">
      <div *ngIf="link_ebit" class="mr-6 lg:mr-10">
        <vip-custom-link
          *ngIf="link_ebit"
          [openInNewTab]="true"
          [url]="link_ebit"
          [ariaDescribedby]="'Avaliado pelos consumidores ebit'"
          [ariaLabel]="'Avaliado pelos consumidores ebit'"
          [useHref]="true"
        >
          <vip-image
            imageUrl="{{ imagesPath }}selo-ebit.png"
            classNames="w-full h-auto"
            alt="Avaliado pelos consumidores ebit"
          >
          </vip-image>
        </vip-custom-link>
      </div>
      <div *ngIf="url_google" class="flex justify-center">
        <vip-custom-link
          *ngIf="url_google"
          [openInNewTab]="true"
          [url]="url_google"
          [ariaDescribedby]="'Google Safe Browsing'"
          [ariaLabel]="'Google Safe Browsing'"
          class="py-2 pr-7"
          [useHref]="true"
        >
          <vip-image
            imageUrl="{{ imagesPath }}selo-google-safe.png"
            classNames="w-full h-auto"
            alt="Google Safe Browsing"
          >
          </vip-image>
        </vip-custom-link>
      </div>
    </div>
  </div>
</footer>
