.vip-dropdown-secoes {
  @apply p-4 text-xs rounded shadow-sm;

  min-height: 100%;
  max-width: 21.0802rem;
  color: var(--vip-color-first-default-main);
  background-color: var(--vip-color-app-background-main);

  &.categorias-layout {
    max-width: none;
    @apply flex flex-row flex-wrap gap-10;
  }

  .secao-container {
    column-count: auto;
    column-gap: 3.125rem; // 50px
    row-gap: 0.938rem; // 15px

    &.count-2 {
      column-count: 2;
    }

    &.count-3 {
      column-count: 3;
    }
  }

  .secao-wrapper {
    @apply flex flex-col mb-3 break-inside-avoid;
  }

  b {
    @apply text-sm font-medium;
  }
  .submenu-item {
    @apply text-sm;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    cursor: pointer;
    width: max-content;
    max-width: 17.0625rem;

    &:hover {
      color: var(--vip-color-secondary-main);
    }
  }
  .show-more-button {
    @apply mt-2 text-center cursor-pointer;
    color: var(--vip-color-secondary-main);
  }
}
