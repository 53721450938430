<section class="my-3">
  <div
    *ngFor="let item of items; index as i; let last = last"
    [class.disabled]="item.disabled"
  >
    <header
      id="{{ 'content-' + i }}"
      (click)="item.disabled ? {} : toggleState(i)"
    >
      <ng-container
        *ngTemplateOutlet="item?.header?.templateRef || null"
      ></ng-container>
      <button
        id="{{ 'content-' + i + generateRandomId() }}"
        class="accordion__toggle-btn"
        [disabled]="item.disabled"
        [attr.aria-expanded]="expanded.has(i) ? 'true' : 'false'"
        [attr.aria-controls]="'content-' + i + 'control'"
        [attr.aria-label]="
          expanded.has(i) ? 'Recolher seção' : 'Expandir seção'
        "
      >
        <i
          *ngIf="expanded.has(i)"
          id="{{ 'content-' + i }}"
          class="icon-minimize"
        ></i>
        <i
          *ngIf="!expanded.has(i)"
          id="{{ 'content-' + i }}"
          class="icon-add"
        ></i>
      </button>
    </header>
    <div
      [id]="'content-' + i"
      [@contentExpansion]="expanded.has(i) ? 'expanded' : 'collapsed'"
    >
      <ng-container
        *ngTemplateOutlet="item?.content?.templateRef || null"
      ></ng-container>
    </div>
    <hr class="vip-divider mt-3 mb-3" [class.hidden]="last" />
  </div>
</section>
