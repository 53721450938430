header.header-sub-menu-mobile {
  @apply flex items-center flex-wrap justify-between pt-3 top-0 z-50;
  max-height: 24.375rem;
  section {
    display: flex;
    width: 100%;
  }
  background-color: var(
    --vip-component-header-desktop-color-background,
    #ffffff
  );

  &.scrolling {
    @apply w-full fixed z-50 top-0;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.2);
  }
}

.vip-menu-btn {
  @apply ml-3;
  button {
    padding: 0 !important;
  }
}
.color-text-desktop {
  color: var(
    --vip-component-header-desktop-color-text,
    var(--vip-color-first-default-main)
  );
}
.icon-menu {
  margin-right: 0;
}

.vip-entrega-retirada-mobile-btn {
  @apply mr-3 w-full;

  .vip-button-medium.vip-button-normal {
    @apply px-0 justify-start;
  }

  .vip-endereco-wrapper {
    @apply mr-3;
    p {
      @apply whitespace-normal;
    }
  }
}

i.vip-icon {
  margin: 0.063rem 0.188rem;
}
i.icone-expand_more {
  color: var(
    --vip-component-header-desktop-color-text,
    var(--vip-color-first-default-main)
  );
}
.vip-carrinho-mobile-btn {
  margin-right: 0.938rem;

  button {
    padding: 0 !important;
  }

  vip-badge {
    color: #fff;
  }
}

.vip-icon {
  @apply text-3xl leading-none;
  color: var(
    --vip-component-header-desktop-color-icon,
    var(--vip-color-secondary-main)
  );

  &.icon-expand_more {
    @apply mr-0 ml-1;
    color: var(--vip-color-secondary-default);

    &.expanded {
      transform: rotateX(180deg);
      -webkit-transform: rotateX(180deg);
    }
  }
}

.vip-logo-mobile {
  margin: auto;
  height: 35px;
  &.is-televendas {
    min-width: 9rem; // 144px
    min-height: 2.75rem; // 44px
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.background-submenu-responsive {
  background-color: var(--vip-component-header-desktop-color-background);
}
.vip-dropdown-container {
  @apply flex max-w-5xl;
  .vip-dropdown-content {
    &.entrega-retirada {
      width: 22.5rem;
      transform: translateY(3.438rem) translateX(0.625rem);
    }
  }
}
