import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { ImageModule } from '@vip/ui/image';
import { NgxMaskPipe, NgxMaskModule } from 'ngx-mask';
import { RouterModule } from '@angular/router';
import { AccordionModule } from '@vip/ui/accordion';
import { CustomLinkComponent } from '@vip/container/custom-link';
@NgModule({
  imports: [
    CommonModule,
    ImageModule,
    AccordionModule,
    RouterModule,
    NgxMaskModule.forRoot(),
    CustomLinkComponent,
  ],
  declarations: [FooterComponent],
  exports: [FooterComponent],
  providers: [NgxMaskPipe],
})
export class FooterModule {}
