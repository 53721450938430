import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AtualizarCarrinhoService } from '@vip/container/carrinho';
import { AuthModule } from '@vip/state/auth';
import { BuscaStateModule } from '@vip/state/busca';
import { CentroDistribuicaoStateModule } from '@vip/state/centro-distribuicao';
import { InstitucionalStateModule } from '@vip/state/institucional';
import { FooterModule } from '@vip/ui/footer';
import { HeaderDesktopModule } from '@vip/ui/header-desktop';
import { LayoutDesktopContainerComponent } from './layout-desktop-container/layout-desktop-container.component';
import { ClassificacaoMercadologicaStateModule } from '@vip/state/classificacao-mercadologica';
import { ProdutoFacade } from '@vip/state/produto';
import { EnderecoStateModule } from '@vip/state/endereco';
import { EnderecoModule } from '@vip/views/endereco';
import { CoreModule } from '@vip/core';
import { CashbackStateModule } from '@vip/state/cashback';
import { NotificacaoStateModule } from '@vip/state/notificacao';
import { ModalCompletarCadastroModule } from '@vip/container/modal-completar-cadastro';
import { TelevendasStateModule } from '@vip/state/televendas';
import { CookiesModule } from '@vip/ui/cookies';
import { WhatsAppModule } from '@vip/ui/whats-app';

@NgModule({
  imports: [
    CookiesModule,
    CommonModule,
    AuthModule,
    HeaderDesktopModule,
    FooterModule,
    RouterModule,
    InstitucionalStateModule,
    BuscaStateModule,
    CentroDistribuicaoStateModule,
    ClassificacaoMercadologicaStateModule,
    EnderecoStateModule,
    EnderecoModule,
    CoreModule,
    NotificacaoStateModule,
    CashbackStateModule,
    ModalCompletarCadastroModule,
    TelevendasStateModule,
    WhatsAppModule,
  ],
  declarations: [LayoutDesktopContainerComponent],
  exports: [LayoutDesktopContainerComponent],
  providers: [AtualizarCarrinhoService, ProdutoFacade],
})
export class LayoutDesktopContainerModule {}
