import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  input,
  Input,
  Output,
} from '@angular/core';
import {
  MenuOptions,
  IClassificacaoMercadologica,
  ITelevendas,
} from '@vip/core';
import { HeaderDesktopBaseDirective } from './header-desktop-base.directive';
import { SearchEvent } from '@vip/ui/search';

@Component({
  selector: 'vip-header-desktop',
  templateUrl: './header-desktop.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class HeaderDesktopComponent
  extends HeaderDesktopBaseDirective
  implements AfterViewInit
{
  @Input()
  options?: MenuOptions;

  @Input()
  departamentos: IClassificacaoMercadologica[] = [];

  @Input() fallbackImageUrl?: string;

  @Input()
  exibeCashback!: boolean;

  @Input()
  saldoCashback = 0;

  @Input()
  saldoCashbackError: string | null = null;

  @Input()
  quantidadeNaoLida = 0;

  @Input()
  set filiaisTelevendas(value: ITelevendas | null) {
    this._filiaisTelevenda = value ?? null;
  }

  get filiaisTelevendas(): ITelevendas | null {
    return this._filiaisTelevenda;
  }

  enderecosLoading = input<boolean | null>(false);

  @Output()
  searchMobile = new EventEmitter<SearchEvent>();

  getScreenWidth = 0;
  _filiaisTelevenda: ITelevendas | null = null;

  ngAfterViewInit(): void {
    this.getScreenWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event) {
    this.getScreenWidth = (event.target as Window).innerWidth;
  }

  handleMobileSearchEvent(event: SearchEvent): void {
    event.stopPropagation();
    if (event.type === 'SearchEvent.enter') {
      return;
    }
    this.searchValue = event.value;
    this.searchMobile.emit(event);
  }
}
