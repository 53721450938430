<header [ngClass]="{ scrolling: scrollingShadow }">
  <a class="vip-logo" [class.is-televendas]="isTelevendas" routerLink="/">
    <vip-image
      *ngIf="logoSrc"
      [hidden]="!imageLoaded()"
      class="flex justify-center vip-logo-image"
      [imageUrl]="logoSrc"
      [fallbackImageUrl]="fallbackImageUrl"
      [hiddenOnError]="true"
      role="img"
      alt="Logo do {{ isTelevendas ? 'Televendas' : 'Mercado' }}"
      ariaLabel="logo principal do {{
        isTelevendas ? 'televendas' : 'mercado'
      }}"
      (imageLoaded)="imageLoaded.set(true)"
      (notShowingImage)="imageLoaded.set(true)"
      [customSize]="true"
    ></vip-image>
    @if (!imageLoaded()) {
      <div
        *vipSkeleton="true; width: '270px'; height: '69px'; radius: '5px'"
      ></div>
    }
  </a>
  <div class="flex flex-1 items-center">
    <div
      vipDropdown
      #dropdownBuscaRapida
      [dontTranslate]="true"
      [disableDropdown]="lockSearchDropdown"
      id="dropdown-busca-rapida"
      class="vip-dropdown-container"
    >
      <button class="hidden" vipDropdownButton></button>
      <vip-search-menu
        vipDropdownContent
        class="vip-dropdown-content search-menu"
        [borderInfinite]="true"
        [suggestions]="termos"
        [products]="produtos"
        [termoPesquisado]="searchValue"
        [produtosNoCarrinho]="produtosNoCarrinho"
        [isLogged]="!!cliente"
        [isLoading]="loadingBuscaRapida"
        (searchByTermClick)="handleSearchByTermClick($event)"
        (productClick)="handleProductClick($event)"
        (spinChanged)="handleSpinChangeClick($event)"
        (tagClick)="onTagClick()"
        (visible)="registerSponsoredProductVisibility($event)"
        (openedConfirmDeleteModal)="lockSearchDropdown = $event"
        (formProcuraProduto)="formProcuraProduto()"
      ></vip-search-menu>
    </div>

    <vip-search
      class="pt-1"
      placeholder="O que você precisa?"
      [focusOnInit]="isFocused"
      [isDesktop]="isDesktop"
      [value]="searchValue"
      (clearEmitter)="dropdowns.first.close(); searchValue = ''"
      (search)="handleQuickSearchEvent($event)"
      (click)="handleSearchClick($event)"
      (focusOnInitChange)="openSearchMenuOnFocus($event)"
    ></vip-search>
    @if (enderecosLoading()) {
      <div
        *vipSkeleton="true; width: '150px'; height: '48px'; radius: '5px'"
      ></div>
    } @else {
      <div
        id="dropdown-entrega"
        vipDropdown
        [dontTranslate]="true"
        (openChange)="dropdownEntregaRetiradaOpen = $event"
        class="vip-dropdown-container"
        [disableDropdown]="disableEntregaRetirada"
      >
        <vip-button
          vipDropdownButton
          type="normal"
          size="small"
          class="vip-entrega-retirada-btn"
          [ariaExpanded]="dropdownEntregaRetiradaOpen"
          [ariaHaspopup]="true"
          [disabled]="disableEntregaRetirada"
        >
          <i class="vip-icon {{ iconEntregaRetirada }}"></i>
          <div
            class="vip-entrega-retirada-wrapper flex flex-col vip-color-first-default text-start whitespace-nowrap items-baseline"
          >
            <ng-container *ngIf="enderecoSelecionado; else noAddressTemplate">
              <h2 class="text-tiny">
                {{
                  retiradaLoja ? 'Retirar na loja:' : 'Entregar no endereço:'
                }}
              </h2>
              <div class="vip-endereco-wrapper">
                <p
                  class="text-xs font-bold inline vip-component-header-desktop-color-text"
                >
                  {{ enderecoSelecionado.endereco_formatado }}
                </p>
              </div>
            </ng-container>
            <ng-template #noAddressTemplate>
              <h2 class="text-xs">Entrega ou Retirada</h2>
            </ng-template>
          </div>
          <i
            *ngIf="!disableEntregaRetirada"
            class="vip-icon icon-expand_more"
            [class.expanded]="dropdownEntregaRetiradaOpen"
          ></i>
        </vip-button>
        <vip-entrega-retirada
          class="vip-dropdown-content entrega-retirada"
          vipDropdownContent
          [enderecos]="enderecos"
          [cdsEntrega]="cdsEntrega"
          [cdsRetirada]="cdsRetirada"
          [realizaEntrega]="realizaEntrega"
          [enderecoSelecionado]="enderecoSelecionado"
          [cdSelecionado]="cdSelecionado || null"
          [clienteLogado]="!!cliente"
          [possuiLojaAutonoma]="possuiLojaAutonoma"
          [isLojaAutonoma]="isLojaAutonoma"
          [isFromResumoCompra]="isFromResumoCompra"
          [formaEntrega]="novaFormaEntrega || formaEntregaFromState"
          [formaEntregaFromState]="formaEntregaFromState"
          [isMobile]="false"
          (enderecoEntregaChange)="enderecoEntregaChange.emit($event)"
          (formaEntregaChange)="formaEntregaChange.emit($event)"
          (cdChange)="cdChange.emit($event)"
          (entregaChange)="entregaChange.emit($event)"
          (cadastrarEndereco)="cadastrarEndereco.emit($event)"
          (alterarEndereco)="alterarEndereco.emit($event)"
          (informarCepClick)="informarCepClick.emit()"
        ></vip-entrega-retirada>
      </div>
    }
    <article
      *ngIf="
        (cdSelecionado && !retiradaLoja) ||
        (novaFormaEntrega === formaEntregaEnum.TIPO_RETIRADA &&
          formaEntregaFromState === formaEntregaEnum.TIPO_ENTREGA)
      "
      data-cy="cd-info"
      class="flex flex-nowrap items-center ml-1"
      [ngClass]="{ 'ml-3 mr-2': isTelevendasSemCliente }"
    >
      <i class="vip-icon icon-store_mall"></i>
      <div
        class="flex flex-col vip-color-first-default text-start whitespace-nowrap items-baseline"
      >
        <h2 class="text-tiny">Comprando na Loja:</h2>
        <div class="vip-cd-wrapper">
          <p class="text-xs font-bold inline">
            {{
              novaFormaEntrega === formaEntregaEnum.TIPO_RETIRADA &&
              formaEntregaFromState === formaEntregaEnum.TIPO_ENTREGA
                ? cdSelecionadoProperty?.nome
                : cdSelecionado?.nome
            }}
          </p>
        </div>
      </div>
    </article>
    <div
      *ngIf="isTelevendas && !isLogged; else loggedInDropdown"
      vipDropdown
      id="dropdown-selecionar-cliente"
      class="vip-dropdown-container"
      [dontTranslate]="true"
      (openChange)="dropdownTelevendaContaOpen = $event"
    >
      <vip-button
        vipDropdownButton
        type="normal"
        size="small"
        class="vip-usuario-btn"
      >
        <i class="vip-icon icon-person"></i>
        <h2
          class="text-xs vip-color-first-default text-start whitespace-nowrap items-baseline"
        >
          Selecionar Cliente
        </h2>
        <i class="vip-icon icon-expand_more"></i>
      </vip-button>
      <div
        vipDropdownContent
        class="vip-dropdown-content cliente-televendas custom-dropdown-content"
      >
        <vip-selecionar-cliente-televendas
          [isDesktopResponsive]="isDesktop"
          (openModalCepRef)="openModalCepRef.emit($event)"
          (openDesktopCepModal)="openDesktopCepModal.emit($event)"
          [filiaisTelevendas]="_filiaisTelevenda"
          (searchClickTelevendas)="searchClickTelevendas.emit($event)"
          (clienteTelevendaSearch)="(clienteTelevendasSearch)"
          [clientesTelevenda]="clientesTelevenda"
          (itemSelected)="selecionarCliente($event)"
          (selectedFilial)="selectedFilial.emit($event)"
          [loadingTelevendas]="loadingTelevendas"
          [isTelevendas]="isTelevendas"
        ></vip-selecionar-cliente-televendas>
      </div>
    </div>

    <ng-template #loggedInDropdown>
      <div
        vipDropdown
        id="dropdown-minha-conta"
        class="vip-dropdown-container"
        [dontTranslate]="true"
        [disableDropdown]="!cliente"
        (openChange)="dropdownMinhaContaOpen = $event"
      >
        <vip-button
          vipDropdownButton
          type="normal"
          size="small"
          class="vip-usuario-btn vip-button-header-desktop"
          (btnClick)="handleUserBtnClick()"
          [ariaExpanded]="dropdownMinhaContaOpen"
          [ariaHaspopup]="!!cliente"
        >
          <i
            class="vip-icon {{
              isTelevendas && cliente ? 'icon-people' : 'icon-person'
            }}"
          ></i>
          <div
            *ngIf="!loading; else userSkeleton"
            data-cy="usuario-info"
            class="flex flex-col cursor-pointer vip-color-first-default text-start whitespace-nowrap items-baseline"
          >
            <div class="vip-user-wrapper">
              <p
                data-cy="nome-cliente"
                *ngIf="!!cliente && !isTelevendas"
                class="text-xs inline vip-component-header-desktop-color-text"
              >
                {{ cliente.nome_completo }}
              </p>
            </div>
            <h2 *ngIf="!cliente && !isTelevendas" class="text-xs flex flex-col">
              <span class="font-normal">Olá, faça seu login </span>
              <span class="font-bold">ou cadastre-se</span>
            </h2>
            <h2 *ngIf="isTelevendas && !cliente" class="text-xs">
              Selecionar cliente
            </h2>
            <div *ngIf="isTelevendas && cliente" class="text-xs flex flex-col">
              <h2 class="font-normal">Efetuando compra para:</h2>
              <div class="vip-user-wrapper">
                <p data-cy="nome-cliente-televendas" class="font-bold inline">
                  {{ cliente.nome_completo }}
                </p>
              </div>
            </div>
          </div>
          <ng-template #userSkeleton>
            <ng-container
              *vipSkeleton="
                true;
                width: '5rem';
                height: '1rem';
                radius: '0.325rem';
                className: ['mr-1']
              "
            ></ng-container>
          </ng-template>
          <i
            *ngIf="!!cliente"
            class="vip-icon icon-expand_more"
            [class.expanded]="dropdownMinhaContaOpen"
          ></i>
        </vip-button>

        <vip-minha-conta-menu
          class="vip-dropdown-content minha-conta"
          vipDropdownContent
          [options]="minhaContaOptions"
          [isLogged]="isLogged"
          [exibeCashback]="exibeCashback"
          [saldoCashback]="saldoCashback"
          [saldoCashbackError]="saldoCashbackError"
          [quantidadeNaoLida]="quantidadeNaoLida"
          (goLogout)="goLogout.emit()"
          (closeDropdown)="closeAllOpenedDropdowns()"
        ></vip-minha-conta-menu>
      </div>
    </ng-template>

    <div
      *ngIf="isTelevendas && !isLogged"
      vipDropdown
      id="dropdown-minha-conta"
      class="vip-dropdown-container"
      [dontTranslate]="true"
      [disableDropdown]="!cliente"
      (openChange)="dropdownMinhaContaOpen = $event"
    >
      <vip-button
        vipDropdownButton
        type="normal"
        size="small"
        class="vip-usuario-btn"
        (btnClick)="encerrarSessaoTelevendas()"
        [ariaExpanded]="dropdownMinhaContaOpen"
        [ariaHaspopup]="!!cliente"
      >
        <i class="vip-icon icon-backspace"></i>
        <div
          *ngIf="!loading; else userSkeleton"
          data-cy="usuario-info"
          class="flex flex-col cursor-pointer vip-color-first-default text-start whitespace-nowrap items-baseline"
        >
          <h2 *ngIf="isTelevendas" class="text-xs">Encerrar sessão</h2>
        </div>
        <ng-template #userSkeleton>
          <ng-container
            *vipSkeleton="
              true;
              width: '5rem';
              height: '1rem';
              radius: '0.325rem';
              className: ['mr-1']
            "
          ></ng-container>
        </ng-template>
      </vip-button>
    </div>

    <div
      vipDropdown
      id="dropdown-carrinho"
      class="vip-dropdown-container"
      [dontTranslate]="true"
    >
      <vip-button
        vipDropdownButton
        [hasIcon]="true"
        [noPadding]="true"
        type="normal"
        size="small"
        class="vip-carrinho-btn"
        [ngClass]="{
          'ml-1': !isTelevendasSemCliente,
          'ml-3': isTelevendasSemCliente,
        }"
        [ariaExpanded]="dropdownCartOpen"
        [ariaHaspopup]="true"
        (click)="handleCartItemClick()"
      >
        <vip-badge
          *ngIf="qtdItensCarrinho && qtdItensCarrinho > 0"
          [number]="qtdItensCarrinho.toString() || '0'"
          position="end"
          [isHeader]="true"
        ></vip-badge>
        <i class="vip-icon icon-shopping_cart"></i>
      </vip-button>

      <vip-carrinho
        class="vip-dropdown-content meu-carrinho"
        vipDropdownContent
        [produtosCarrinho]="produtosCarrinho"
        [produtosFavoritos]="produtosFavoritos"
        [produtosMaisVendidos]="produtosMaisVendidos"
        [carrinhoVazio]="carrinhoVazio"
        [carrinho]="carrinho"
        [produtosNoCarrinho]="produtosNoCarrinho"
        [combosNoCarrinho]="combosNoCarrinho"
        (showMoreClick)="showMoreClick.emit()"
        (produtoChanged)="produtoChanged.emit($event)"
        (resumeClick)="onHandleResumoClick($event)"
        (deleteCarrinhoClick)="handleDeleteCarrinhoClick()"
        (deleteItemClick)="deleteItemClick.emit($event)"
        (addListClick)="addListClick.emit($event)"
        [aviseMeEnviados]="aviseMeEnviados"
        [aviseMeEnviados]="aviseMeEnviados"
        (aviseMeClick)="aviseMeClick.emit($event)"
        (closeClicked)="closeClick()"
        leftClose="icon-close"
        [isDesktop]="isDesktop"
      ></vip-carrinho>
    </div>
  </div>
</header>

<vip-not-found-modal
  [(abrirModalProcurarProduto)]="abrirModalProcurarProduto"
></vip-not-found-modal>
