.vip-dropdown-departamentos {
  display: flex;
  gap: 5px;
  pointer-events: none;

  & > * {
    pointer-events: all;
  }
}
.menu {
  @apply rounded shadow-sm relative;
  color: var(--vip-color-first-default-main);
  min-width: 17.25rem;
  align-self: flex-start;
  background-color: var(--vip-color-app-background-main);

  .menu-item {
    @apply flex items-center px-3 pt-3 text-sm;

    &:hover {
      color: var(--vip-color-secondary-main);
    }
  }

  &:hover {
    // necessário pra manter o espaçamento de 5px entre o departamento
    // as secoes e não perder o efeito de hover
    &::after {
      width: 5px;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: -5px;
    }
  }
}
