import { CurrencyPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CampanhaPatrocinadoService } from '@vip/api';
import { IProduto, ProdutoUtilsService, TipoOfertaEnum } from '@vip/core';
import { ISpinEvent } from '@vip/ui/spin';

@Component({
  selector: 'vip-item-product',
  templateUrl: './item-product.component.html',
  styleUrls: ['./item-product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ItemProductComponent {
  @Input()
  product!: IProduto;

  @Input()
  opened = false;

  @Input() produtosNoCarrinho?: Map<
    number,
    { item_id: number; quantidade: number; observacao?: string }
  > | null = null;

  @Output()
  productClick = new EventEmitter<IProduto>();

  @Output()
  spinChanged = new EventEmitter();

  @Output()
  tagClick: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  openedConfirmDeleteModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private currencyPipe: CurrencyPipe,
    public produtoUtilsService: ProdutoUtilsService,
    private campanhaPatrocinadoService: CampanhaPatrocinadoService,
  ) {}

  get percentOferta(): string {
    if (this.product.em_oferta && this.product.oferta) {
      const diff =
        this.product.oferta.preco_antigo - this.product.oferta.preco_oferta;
      return Math.round((diff * 100) / this.product.oferta.preco_antigo) + '%';
    } else {
      return '';
    }
  }

  get produtoPreco(): string | null {
    if (this.isBrinde) return 'Brinde';

    const { oferta } = this.product;
    const hasOferta = oferta !== null;
    let produtoPreco: number;

    if (hasOferta) {
      if (
        [
          TipoOfertaEnum.PRODUTO_COM_DESCONTO,
          TipoOfertaEnum.CATEGORIA_DE_PRODUTOS,
          TipoOfertaEnum.OFERTA_ATACAREJO,
        ].includes(oferta.tipo_oferta_id) &&
        oferta.quantidade_minima === 1
      ) {
        produtoPreco = oferta.preco_oferta;
      } else {
        produtoPreco = oferta.preco_antigo;
      }
    } else {
      produtoPreco = this.product.preco * (this.product.quantidade || 1);
    }

    return this.currencyPipe.transform(produtoPreco);
  }

  get isCombo(): boolean {
    const { oferta } = this.product;
    return (
      oferta !== null &&
      oferta.tipo_oferta_id === TipoOfertaEnum.COMBO_DE_PRODUTOS
    );
  }

  get isBrinde(): boolean {
    return !!this.product.brinde;
  }

  get itemId(): number {
    return (
      this.produtosNoCarrinho?.get(this.product.produto_id || 0)?.item_id ||
      this.product.produto_id ||
      0
    );
  }

  get observacao(): string {
    return this.product
      ? this.produtoUtilsService.getObservacao(
          this.product,
          this.produtosNoCarrinho,
        )
      : '';
  }

  get exibeTag(): boolean {
    const { oferta } = this.product;
    return (
      this.product.em_oferta &&
      this.product.disponivel &&
      this.percentOferta !== '0.00%' &&
      this.percentOferta !== '0%' &&
      oferta !== null &&
      [
        TipoOfertaEnum.PRODUTO_COM_DESCONTO,
        TipoOfertaEnum.CATEGORIA_DE_PRODUTOS,
      ].includes(oferta.tipo_oferta_id) &&
      oferta.quantidade_minima === 1
    );
  }

  handleProductKeyboardEntry(event: KeyboardEvent, product: IProduto): void {
    if (event.key === 'Enter') this.productClick.emit(product);
  }

  handleClickSpinChange(event: ISpinEvent) {
    event.event.stopPropagation();
    const produto = {
      ...this.product,
      item_id: this.itemId,
      observacao: this.observacao,
      seletor_medida_id: event.seletor_medida_id
        ? event.seletor_medida_id
        : this.product?.seletor_medida_id,
    };
    if (event.quantity < 0) event.quantity = 0;
    this.spinChanged.emit({ ...event, produto });
  }

  cliqueAdsCard() {
    this.campanhaPatrocinadoService.clique(this.product);
  }
}
